import Gleap from 'gleap';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { delay } from 'redux-saga';
import { combineWatchers } from 'redux-saga-combine-watchers';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { quality } from 'components/SecuredImage';
import config from 'config/config';
import { setGlobalError } from 'containers/ErrorHandler/actions';
import { changeLocale } from 'containers/LanguageProvider/actions';
import { setGlobalMessage } from 'containers/MessageHandler/actions';
import globalMessages from 'translations/messages/global-messages';
import Analytics from 'utils/Analytics';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import { generateChunks, uploadMedia } from 'utils/chunkUploader';
import { checkParams } from 'utils/errors/check-params';
import { downloadBlob } from 'utils/file';
import localStorageUser from 'utils/localStorageUser';
import { setBusinessOrganizationInStorage } from 'utils/organisation-utils';
import request, { requestFile } from 'utils/request';
import sessionStorageUser from 'utils/sessionStorageUser';

import {
  addUserToStore,
  getBusinessOrganization,
  resetPopupBlockObject,
  setAllOrganizations,
  setAllOrganizationsLoadingFlag,
  setBusinessOrganization,
  setEmailRegistration,
  setLoadingFlag,
  setLoggedUserData,
  setLogos,
  setMembers,
  setMembersLoadingFlag,
  setProject,
  setProjectDeleted,
  setSpecialRights,
  setTimezones,
  setUserAccessInfo,
  setUserLinkedCompanies,
  setWorkspace,
} from './actions';
import {
  ADD_TO_PROJECT_BY_INVITATION,
  ATTACH_LOGOS,
  CHECK_EMAIL_REGISTRATION,
  CLEAR_POPUP_BLOCKER_BLOB,
  CREATE_AND_ATTACH_MEDIA,
  DELETE_PROJECT,
  DETACH_LOGO,
  DOWNLOAD_ITEM,
  FETCH_ALL_ORGANIZATIONS,
  FETCH_LOGOS,
  FETCH_SPECIAL_RIGHTS,
  FETCH_USER_ACCESS_INFO,
  GET_BUSINESS_ORGANIZATION,
  GET_COMPANY_INFO,
  GET_CURRENT_OFFER,
  GET_MEMBERS,
  GET_PROJECT,
  GET_TIMEZONES,
  GET_USER_DATA,
  GET_USER_LINKED_COMPANIES,
  GET_WORKSPACE,
  GET_WORKSPACE_BY_ID,
  LEAVE_PROJECT,
  SAVE_BUSINESS_ORGANISATION,
  SAVE_WORKSPACE,
  VERIFY_EMAIL,
} from './constants';
import initCompanySaga from './globalStore/company/saga';
import initDocumentsSaga from './globalStore/documents/saga';
import initFormsSaga from './globalStore/forms/saga';
import initObservationsSaga from './globalStore/observations/saga';
import initPlansSaga from './globalStore/plans/saga';
import initTradesSaga from './globalStore/trades/saga';
import initUsersSaga from './globalStore/users/saga';
import {
  ADD_MODULE,
  CREATE_MODULE,
  EDIT_MODULE,
  GET_CURRENT_MODULE,
  GET_MODULES_FORMS,
  GET_MODULES_OBSERVATIONS_AND_MEETING,
  UPDATE_MODULE_SETTINGS,
} from './modules/constants';
import {
  addModule,
  createModule,
  editModule,
  fetchCurrentModule,
  fetchModulesForms,
  fetchModulesObservationsAndMeeting,
  updateModuleSettings,
} from './modules/generators';
import { selectLoggedUserData, selectRouteParams } from './selectors';

export const API_ORGANIZATION_VERSION = 'v1.1';

function* fetchSpecialRights() {
  yield put(setLoadingFlag(true));
  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/authorization/special-rights`;
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    yield put(setSpecialRights(data));
  }
  yield put(setLoadingFlag(false));
}

export function* fetchCompanyInfo({ params, id, callback }) {
  checkParams({ params, keys: ['idData'] });

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    params.idData
  }/companies/${id}`;

  const options = {
    method: 'GET',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
  };

  const data = yield call(request, requestURL, options, {
    errorRedirect: false,
  });
  if (data && !data.message && callback) {
    yield call(callback, data);
  }
}

export function* fetchTimezones() {
  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/timezones`;

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    yield put(setTimezones(data));
  }
}

export function* fetchMembers({ routeParams, params }) {
  yield put(setMembersLoadingFlag(true));
  checkParams({ params: routeParams, keys: ['idData'] });
  const { projectId, callback, searchParams } = params || {};
  const { limit, offset, searchTerm } = searchParams || {};
  const projectIdRequest = projectId || routeParams.idData;

  const limitForParams = limit || 500;
  const offsetForParams = offset || 0;

  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/projects/${projectIdRequest}/members?limit=${limitForParams}&offset=${offsetForParams}`;

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      search_term: searchTerm || '',
      with_disabled_members: true,
    }),
  };

  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    const sortedMembers = [...(data.members || [])].sort((a, b) =>
      a.first_name.localeCompare(b.first_name),
    );
    if (callback) {
      yield call(callback, sortedMembers);
    } else {
      yield put(setMembers(sortedMembers));
    }
  }
  yield put(setMembersLoadingFlag(false));
}

function* processFetchUserResponse(data, userId, id, expDate, callback) {
  if (data && data?.message) {
    yield put(setLoadingFlag(false));
  } else {
    if (userId === id) {
      const { locale } = data;
      const locale_language = localStorageUser.getLanguageUserFromLocalStorage();
      if (locale?.length > 0 && locale !== locale_language) {
        moment.locale(locale);
        yield put(changeLocale(locale));
      }
      yield put(setLoggedUserData(data));
    }
    if (id) {
      yield put(
        addUserToStore({
          id,
          user: {
            ...data,
            expirationDate: expDate,
          },
        }),
      );
      localStorageUser.setUserInUserStore({
        id,
        user: {
          ...data,
          expirationDate: expDate,
        },
      });
    }
    if (callback) {
      yield call(callback, data);
    }
  }
}

export function* fetchUserData({ id, callback, forceUpdate }) {
  if (id) {
    const findUser = localStorageUser.getUserFromUserStore(id); // This should be selected from redux store not from storage.
    const oktaToken = localStorageUser.getOktaTokenStorage();
    const userId = oktaToken?.accessToken?.claims?.fcUserId;
    const requestURL = `${checkOrSetSlash(
      config.apiHostGateway,
      'apiHostGateway',
    )}api/user-infos/${id}`;
    const options = {
      method: 'GET',
      headers: {
        'Cache-Control': 'No-Store',
        'Content-Type': 'application/json',
      },
    };
    const dateNow = new Date();
    if (
      !forceUpdate &&
      findUser &&
      !findUser?.loading &&
      new Date(findUser.expirationDate) > dateNow
    ) {
      if (callback) {
        yield call(callback, findUser);
      }
    } else {
      const expDate = new Date();
      expDate.setHours(expDate.getHours() + 4);
      localStorageUser.setUserInUserStore({
        id,
        user: {
          loading: true,
          expirationDate: expDate,
        },
      });
      yield put(
        addUserToStore({
          id,
          user: {
            loading: true,
            expirationDate: expDate,
          },
        }),
      );
      const data = yield call(request, requestURL, options, {
        errorRedirect: false,
      });
      yield* processFetchUserResponse(data, userId, id, expDate, callback);
    }
  }
}

function* fetchUserAccessInfo() {
  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/user`;
  const options = {
    method: 'GET',
    headers: { 'Cache-Control': 'No-Store', 'Content-Type': 'application/json' },
  };

  try {
    const data = yield call(request, requestURL, options);
    yield put(setUserAccessInfo(data));
  } catch (err) {
    console.error('Failed to fetch user access info', err);
  }
}

function* fetchAllOrganizations({ callback, offset, search_pattern, limit = 400, init = true }) {
  const isSearching = offset || offset === 0 || search_pattern;
  yield put(setLoadingFlag(true));
  if (!isSearching) {
    yield put(setAllOrganizationsLoadingFlag(true));
  }

  const requestURL = `${checkOrSetSlash(
    config.apiHostGatewayGlobal,
    'apiHostGatewayGlobal',
  )}api/organizations/user_organizations?limit=${limit}&offset=${offset || 0}`;

  const requestURLWithSearchPattern = search_pattern
    ? `${requestURL}&search_term=${search_pattern}`
    : requestURL;

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const data = yield call(request, requestURLWithSearchPattern, options, {
    errorRedirect: true,
    redirectionUrl: 'login',
  });
  if (data && !data.message) {
    if (init) {
      yield put(setAllOrganizations(data.organizations || []));
    }
    if (callback) {
      yield call(callback, data);
    }
  }
  yield put(setLoadingFlag(false));
  if (!isSearching) {
    yield put(setAllOrganizationsLoadingFlag(false));
  }
}

function* updateBusinessOrganization({ organization, newName, successCallback }) {
  yield put(setLoadingFlag(true));

  const {
    trigram,
    code,
    salesforce_reference,
    address,
    domain,
    organization_type_id,
    paying_members_by_default,
    paying_invitees_by_default,
    sub_vertical_id,
    specialties,
  } = organization;

  const requestURL = `${checkOrSetSlash(
    config.apiHostGatewayGlobal,
    'apiHostGatewayGlobal',
  )}api/${API_ORGANIZATION_VERSION}/organizations/${organization.id}`;

  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name: newName,
      trigram,
      code,
      salesforce_reference,
      address,
      domain,
      organization_type_id,
      paying_members_by_default,
      paying_invitees_by_default,
      sub_vertical_id,
      specialties,
    }),
  };
  const data = yield call(request, requestURL, options);

  if (data && (data.message || data.response?.errors)) {
    let dataResponse = data;
    let errorsArray = [];
    if (data.response?.errors) {
      Object.keys(data.response?.errors).forEach(key => {
        errorsArray = [...errorsArray, ...data.response?.errors[key]];
      });
      dataResponse = { response: { errors: errorsArray } };
    }
    yield put(setGlobalError(dataResponse));
    yield put(setLoadingFlag(false));
  } else {
    if (successCallback) {
      yield put(getBusinessOrganization(organization.id, successCallback));
    }
    yield put(setLoadingFlag(false));
  }
}

export function* downloadItem({ url, fileName, analyticsTrackName }) {
  yield put(
    setGlobalMessage({
      type: 'success',
      intlMessage: {
        id: 'download_in_progress',
      },
      active: true,
    }),
  );
  yield put(setLoadingFlag(true));
  const options = {
    method: 'GET',
    headers: {},
  };
  const data = yield call(requestFile, `${url}/download?quality=${quality.original}`, options);
  if (data && data?.status !== null && data?.message) {
    yield put(setGlobalError(data));
  } else {
    if (analyticsTrackName) {
      Analytics.track(analyticsTrackName);
    }
    const reader = data.body?.getReader();
    const result = [];
    if (!reader) return;
    reader.read().then(function processText({ done, value }) {
      if (done) {
        downloadBlob(result, fileName);
        return;
      }
      result.push(value);
      reader.read().then(processText);
    });
  }
  yield put(setLoadingFlag(false));
}

function* fetchOrganizationInfo({ organizationId, callback, errorCallback }) {
  yield put(setLoadingFlag(true));

  const requestURL = `${checkOrSetSlash(
    config.apiHostGatewayGlobal,
    'apiHostGatewayGlobal',
  )}api/${API_ORGANIZATION_VERSION}/organizations/${organizationId}`;

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const data = yield call(request, requestURL, options, {
    errorRedirect: !errorCallback,
  });

  if (data && !data.message && !data.status) {
    setBusinessOrganizationInStorage(data);
    yield put(setBusinessOrganization(data));

    if (callback) {
      yield call(callback, data);
    }

    Gleap.setCustomData('organization_id', data.id);
    Gleap.setCustomData('organization_public_id', data.public_id);
  } else if (errorCallback) {
    yield call(errorCallback, data);
  }
  yield put(setLoadingFlag(false));
  return data;
}

export function* fetchProject({ projectId, callback, updateProjectInfo = true }) {
  yield put(setLoadingFlag(true));
  const userInfo = yield select(selectLoggedUserData());
  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/projects/${projectId}`;

  const options = {
    method: 'GET',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
    errorRedirect: false,
  };

  const data = yield call(request, requestURL, options, {
    errorRedirect: false,
    errorCustom: true,
  });

  if ((data?.status === 404 || data?.status === 400 || data?.status === 410) && callback) {
    yield call(callback, data);
  } else if (data && !data.message && data?.status !== 410 && updateProjectInfo) {
    const isMember = data?.project_roles.some(role =>
      role?.user_ids.some(id => id === userInfo?.user_id),
    );
    const project = { ...data, current_user_in_project: isMember };
    yield put(setProject(project));
    localStorageUser.addRecentlyViewedProjects(project);
    if (callback) {
      yield call(callback);
    }

    Gleap.setCustomData('project_id', project.project_id);
    Gleap.setCustomData('project_name', project.name);
    Gleap.setCustomData('workspace_id', project.workspace_id);
  } else if (data && !data.message && data?.status !== 410 && callback) {
    yield call(callback, data);
  } else if (data?.status === 410) {
    yield put(setProjectDeleted(true));
  }
  yield put(setLoadingFlag(false));
}

export function* addToProjectByInvitation({ requestURL, callback }) {
  const options = {
    method: 'POST',
  };
  yield call(request, requestURL, options);
  if (callback) {
    yield call(callback);
  }
}

function* checkEmailRegistration({ email, callback, organizationId, deepLinkInfoId }) {
  yield put(setLoadingFlag(true));
  let requestURL = `${checkOrSetSlash(
    config.apiHostGatewayGlobal,
    'apiHostGatewayGlobal',
  )}api/v1.1/user/user-authentication-info/${email}/`;

  if (organizationId && deepLinkInfoId) {
    requestURL = `${checkOrSetSlash(
      config.apiHostGatewayGlobal,
      'apiHostGatewayGlobal',
    )}api/v1.1/user/user-authentication-info/${email}?organization_id=${organizationId}&deep_link_info_id=${deepLinkInfoId}`;
  }

  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${config.apiRegistrationToken}`,
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
  };

  sessionStorageUser.setFinalcadTempRegistrationInfo(null);
  sessionStorageUser.setIsSignUp(null);
  const data = yield call(request, requestURL, options);
  const fullTempRegistrationInfo = { ...data, email };
  if (data && !data.message) {
    if (fullTempRegistrationInfo.is_email_verified) {
      yield put(setEmailRegistration(fullTempRegistrationInfo));
      sessionStorageUser.setFinalcadTempRegistrationInfo(fullTempRegistrationInfo);
      if (data?.invitations && !isEmpty(data?.invitations)) {
        sessionStorageUser.setInvitationsLink([...data.invitations]);
      }
    } else {
      Analytics.track('users_sign_up_failed', {
        source: 'password',
        failure_reason: 'email_verification_failed',
      });
      yield put(
        setGlobalError({
          data,
          customErrorMessageIntl: globalMessages.inexisting_email,
        }),
      );
    }
    if (callback) {
      callback(fullTempRegistrationInfo);
    }
  }
  yield put(setLoadingFlag(false));
}
export function* fetchCurrentOffer({ businessOrganisationId, callback }) {
  yield put(setLoadingFlag(true));
  const requestURL = `${checkOrSetSlash(
    config.apiHostGatewayGlobal,
    'apiHostGatewayGlobal',
  )}api/subscriptions/${businessOrganisationId}/current`;

  const options = {
    method: 'GET',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
  };
  const data = yield call(request, requestURL, options, {
    errorCustom: true,
  });
  if (data && !data.message) {
    localStorageUser.setFinalcadCurrentOffer(data);
    Gleap.setCustomData('current_offer', data.name);
    if (callback) {
      yield call(callback, data);
    }
  }
  yield put(setLoadingFlag(false));
  return data;
}

export function* verifyEmail({ email, callback }) {
  yield put(setLoadingFlag(true));
  const requestURL = `${checkOrSetSlash(
    config.apiHostGatewayGlobal,
    'apiHostGatewayGlobal',
  )}api/v1.1/user/verify-email/${email}`;

  const options = {
    method: 'POST',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
  };
  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    if (callback) yield call(callback, data);
    if (!data.is_email_verified) {
      yield put(
        setGlobalError({
          data,
          customErrorMessageIntl: globalMessages.inexisting_email,
        }),
      );
    }
  }
  yield put(setLoadingFlag(false));
}

export function* fetchWorkspace({ params, callback }) {
  checkParams({ params, keys: ['organizationId', 'workspaceId'] });

  const requestURL = `${checkOrSetSlash(
    config.apiHostGatewayGlobal,
    'apiHostGatewayGlobal',
  )}api/organizations/${params.organizationId}/workspaces/${params.workspaceId}`;

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    yield put(setWorkspace(data));
    if (callback) {
      yield call(callback, data);
    }
  }
}

function* getWorkspaceById({ id, callback }) {
  const params = yield select(selectRouteParams());
  const requestURL = `${checkOrSetSlash(
    config.apiHostGatewayGlobal,
    'apiHostGatewayGlobal',
  )}api/organizations/${params.organizationId}/workspaces/${id}`;

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    if (callback) {
      yield call(callback, data);
    }
  }
}

function* updateWorkspace({ workspace }) {
  yield put(setLoadingFlag(true));
  const { id, name, parent_id, client_reference } = workspace;
  const route = yield select(selectRouteParams());
  const { organizationId, workspaceId } = route;
  const requestURL = `${checkOrSetSlash(
    config.apiHostGatewayGlobal,
    'apiHostGatewayGlobal',
  )}api/organizations/${organizationId}/workspaces/${workspaceId}`;

  const payload = {
    id,
    name,
    parent_id,
    client_reference,
  };

  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };

  const data = yield call(request, requestURL, options);
  if (data && (!data.message && data?.status !== 410)) {
    Analytics.track('workspace_ws_name_updated');
    yield put(setWorkspace(data));
  }
  yield put(setLoadingFlag(false));
}

export function* leaveProject({ projectId, callback, callBackError }) {
  yield put(setLoadingFlag(true));
  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/projects/${projectId}/leave-project`;
  const options = {
    method: 'POST',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
  };
  const data = yield call(request, requestURL, options, {
    errorRedirect: false,
    errorCustom: true,
  });

  if ((data && !data.message) || !data) {
    if (callback) {
      yield call(callback);
    }
  } else {
    yield call(callBackError, data?.response?.code);
  }
  yield put(setLoadingFlag(false));
}

export function* deleteProject({ projectId, callback }) {
  yield put(setLoadingFlag(true));
  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/projects/${projectId}`;
  const options = {
    method: 'DELETE',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
  };
  const data = yield call(request, requestURL, options);
  if ((data && !data.message) || !data) {
    if (callback) {
      yield call(callback);
    }
  }
  yield put(setLoadingFlag(false));
}
export function* fetchUserLinkedCompanies({ params }) {
  checkParams({ params, keys: ['idData'] });

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    params.idData
  }/companies/connected-user`;

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    const companies = data?.companies_ids?.map(el => ({ id: el }));
    yield put(setUserLinkedCompanies(companies));
  }
}

export function* fetchUsersData({ ids, searchTerm }) {
  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v1.0/user-infos/list`;

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user_ids: ids,
      search_term: searchTerm || '',
    }),
  };

  const data = yield call(request, requestURL, options);
  // TODO develop to add the user in localStorage and userStore
  // TODO Technical task, maybe merge the fetchUserData with this one
  if (data && !data.message) {
    return data.users;
  }
  return [];
}

function* createAndAttachMedia({ resource, user }) {
  let files = [];
  const setFilesExternal = fls => {
    files = fls;
  };
  const fetchedData64 = yield call(fetch, resource.resultedBlob);
  const blob = yield fetchedData64.blob();
  const file = new File([blob], resource.name, { type: resource.type });
  generateChunks({
    file,
    filesUpload: [],
    filesToUpload: [file],
    setFilesExternal,
  });
  yield delay(1000); // 1 second is more than enough for the browser to read the file internally
  const requestUploadMediaUrl = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v1.2/users/${user.id}/`;
  const dataUpload = yield call(uploadMedia, files[0], {
    baseUrl: requestUploadMediaUrl,
    parallel: false,
  });
  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v2.1/user-infos/${user.id}/attach-media`;

  const payload = {
    media_resource: {
      root_media_resource_id: dataUpload.id,
      media_resource_id: dataUpload.id,
      media_resource_label: dataUpload.file_name,
    },
  };

  const options = {
    method: 'POST',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };

  yield call(request, requestURL, options, {
    errorRedirect: false,
  });
  yield delay(1000);
  yield call(fetchUserData, { id: user.id, forceUpdate: true });
}

function* clearPopupBlockerBlop() {
  yield put(resetPopupBlockObject());
}

export function* fetchLogos() {
  const route = yield select(selectRouteParams());

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    route.idData
  }/logos`;

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const data = yield call(request, requestURL, options);

  if (data?.message) {
    yield put(setGlobalError(data));
  } else if (data?.logos) {
    yield put(setLogos(data.logos));
  }
}

function* attachLogo({ media }) {
  const route = yield select(selectRouteParams());

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    route.idData
  }/attach-logo`;

  const requestUploadMediaUrl = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v1.2/projects/${route.idData}/`;

  const dataUpload = yield call(uploadMedia, media, {
    baseUrl: requestUploadMediaUrl,
    parallel: false,
    requestHeaders: {
      'Content-Type': 'application/json',
    },
  });

  const body = {
    media_resource: {
      media_resource_id: dataUpload.id,
      media_resource_label: dataUpload.file_name,
      root_media_resource_id: dataUpload.id,
    },
  };

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };

  const data = yield call(request, requestURL, options);
  if (data?.message) {
    yield put(setGlobalError(data));
  }
}

export function* attachLogos({ medias, callback }) {
  for (let i = 0; i < medias.length; i += 1) {
    yield call(attachLogo, { media: medias[i] });
  }
  if (callback) {
    callback();
  }
}

export function* detachLogo({ mediaId, callback }) {
  const route = yield select(selectRouteParams());

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    route.idData
  }/detach-logos`;

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ media_resource_ids: [mediaId] }),
  };

  const data = yield call(request, requestURL, options);
  if (data?.message) {
    yield put(setGlobalError(data));
  } else if (callback) {
    callback();
  }
}

function* globalWrapperSaga() {
  yield takeEvery(DOWNLOAD_ITEM, downloadItem);
  yield takeEvery(GET_COMPANY_INFO, fetchCompanyInfo);
  yield takeEvery(GET_USER_DATA, fetchUserData);
  yield takeEvery(VERIFY_EMAIL, verifyEmail);

  yield takeLatest(ADD_MODULE, addModule);
  yield takeLatest(ADD_TO_PROJECT_BY_INVITATION, addToProjectByInvitation);
  yield takeLatest(CHECK_EMAIL_REGISTRATION, checkEmailRegistration);
  yield takeLatest(CREATE_AND_ATTACH_MEDIA, createAndAttachMedia);
  yield takeLatest(CREATE_MODULE, createModule);
  yield takeLatest(DELETE_PROJECT, deleteProject);
  yield takeLatest(EDIT_MODULE, editModule);
  yield takeLatest(FETCH_ALL_ORGANIZATIONS, fetchAllOrganizations);
  yield takeLatest(FETCH_SPECIAL_RIGHTS, fetchSpecialRights);
  yield takeLatest(GET_BUSINESS_ORGANIZATION, fetchOrganizationInfo);
  yield takeLatest(GET_CURRENT_OFFER, fetchCurrentOffer);
  yield takeLatest(GET_MEMBERS, fetchMembers);
  yield takeLatest(GET_MODULES_OBSERVATIONS_AND_MEETING, fetchModulesObservationsAndMeeting);
  yield takeLatest(GET_MODULES_FORMS, fetchModulesForms);
  yield takeLatest(GET_CURRENT_MODULE, fetchCurrentModule);
  yield takeLatest(UPDATE_MODULE_SETTINGS, updateModuleSettings);
  yield takeEvery(GET_PROJECT, fetchProject);
  yield takeLatest(GET_TIMEZONES, fetchTimezones);
  yield takeLatest(GET_USER_LINKED_COMPANIES, fetchUserLinkedCompanies);
  yield takeLatest(GET_WORKSPACE, fetchWorkspace);
  yield takeEvery(GET_WORKSPACE_BY_ID, getWorkspaceById);
  yield takeLatest(LEAVE_PROJECT, leaveProject);
  yield takeLatest(SAVE_BUSINESS_ORGANISATION, updateBusinessOrganization);
  yield takeLatest(SAVE_WORKSPACE, updateWorkspace);
  yield takeLatest(CLEAR_POPUP_BLOCKER_BLOB, clearPopupBlockerBlop);
  yield takeLatest(FETCH_LOGOS, fetchLogos);
  yield takeLatest(ATTACH_LOGOS, attachLogos);
  yield takeLatest(DETACH_LOGO, detachLogo);
  yield takeLatest(FETCH_USER_ACCESS_INFO, fetchUserAccessInfo);
}

export default function* initGlobalWrapperSaga() {
  yield all(
    combineWatchers([
      initCompanySaga,
      initDocumentsSaga,
      initFormsSaga,
      initObservationsSaga,
      initPlansSaga,
      initTradesSaga,
      initUsersSaga,
      globalWrapperSaga,
    ]),
  );
}
